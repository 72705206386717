<template>
  <validation-observer ref="form" #default="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(onSaveAndExit)">
      <v-dialog
        v-model="dialog"
        scrollable
        persistent
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title>
            {{ $t('assemblyPlanningTaco.cannotEditTitle') }}
          </v-card-title>
          <v-card-text>
            {{ $t('assemblyPlanningTaco.cannotEditSubtitle') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text-none"
              color="primary"
              @click="exit"
            >
              {{ $t('assemblyPlanningTaco.goBack') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-container fluid>
        <v-row
          justify="center"
          :no-gutters="$vuetify.breakpoint.smAndDown"
        >
          <v-col cols="12" xl="9" class="text-justify">
            <v-card flat class="transparent">
              <v-card-text class="pt-0">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.model')"
                      :items="modelList"
                      item-text="model"
                      item-value="model"
                      v-model="selectedModel"
                      @change="fetchPartByModel"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.variant')"
                      :items="variantList"
                      :disabled="saving || !selectedModel"
                      :loading="fetchingMatrix"
                      item-text="variant"
                      item-value="variant"
                      v-model="selectedVariant"
                      @change="filterCustomer"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.customer')"
                      :items="customers"
                      item-text="customername"
                      item-value="customername"
                      :disabled="saving || !selectedVariant"
                      v-model="selectedCustomer"
                      @change="filterLine"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.line')"
                      :items="lineList"
                      item-text="linename"
                      item-value="linename"
                      :disabled="saving || !selectedCustomer"
                      v-model="selectedLine"
                      @change="filterPart"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      :label="$t('assemblyPlanningTaco.remark')"
                      outlined
                      v-model="plan.remark"
                      required
                    ></v-text-field>
                    <!-- <v-autocomplete
                      clearable
                      filled
                      :label="$t('assemblyPlanningTaco.partname')"
                      :items="partList"
                      item-text="partname"
                      item-value="partname"
                      :disabled="saving || !selectedLine"
                      v-model="selectedPart"
                      return-object
                      @change="setPlanParameters"
                    ></v-autocomplete> -->
                  </v-col>
                </v-row>
                <div class="title mb-2">
                  {{ $t('assemblyPlanningTaco.operationalParams') }}
                  <v-btn
                    small
                    outlined
                    class="text-none"
                    :disabled="!selectedMatrix || saving"
                    @click="editParams = !editParams"
                    color="primary"
                  >
                    <template v-if="!editParams">
                      <v-icon left small>mdi-pencil-outline</v-icon>
                      {{ $t('assemblyPlanningTaco.editButton') }}
                    </template>
                    <template v-else>
                      <v-icon left small>mdi-check</v-icon>
                      {{ $t('assemblyPlanningTaco.doneButton') }}
                    </template>
                  </v-btn>
                </div>
                <v-row v-for="macdetails in plan.machinedetails" :key="macdetails.machinename">
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="machinename"
                      rules="required"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningTaco.machine')"
                        type="name"
                        disabled
                        :error-messages="errors"
                        prepend-inner-icon="mdi-clock-fast"
                        outlined
                        v-model="macdetails.machinename"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="cycletime"
                      rules="required|min_value:1"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningTaco.cycletime')"
                        type="number"
                        :disabled="!editParams || saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-clock-fast"
                        outlined
                        :suffix="$t('assemblyPlanningTaco.secs')"
                        v-model="macdetails.stdcycletime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="delaytime"
                      rules="required|min_value:0"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningTaco.delayTime')"
                        type="number"
                        :disabled="!editParams || saving"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-cached"
                        outlined
                        :suffix="$t('assemblyPlanningTaco.secs')"
                        v-model="macdetails.delaytime"
                        hide-details="auto"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <div class="title mt-4 mb-2">
                  {{ $t('assemblyPlanningTaco.planDetails') }}
                </div>
                <v-row>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="quantity"
                      :rules="`required|numeric|min_value:1|multiple_of:${plan.activecavity}`"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningTaco.quantity')"
                        type="number"
                        :disabled="saving || inProgress"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-package-variant-closed"
                        outlined
                        :suffix="$t('assemblyPlanningTaco.pieces')"
                        v-model="plan.plannedquantity"
                        hide-details="auto"
                        @change="onQuantityChange"
                        :hint="`Should be multiple of active cavity(${plan.activecavity})`"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      name="scheduledstart"
                      :rules="`${notStarted
                        ? 'required|greater_than_now'
                        : 'required'}`"
                      #default="{ errors }"
                    >
                      <v-text-field
                        :label="$t('assemblyPlanningTaco.scheduledStartTime')"
                        type="datetime-local"
                        :error-messages="errors"
                        prepend-inner-icon="mdi-calendar-check-outline"
                        outlined
                        v-model="plan.scheduledstart"
                        hide-details="auto"
                        :disabled="saving || inProgress"
                        @change="fetchEstimatedEnd"
                      >
                        <template #append-outer>
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                v-text="'$info'"
                                v-on="on"
                                v-bind="attrs"
                              ></v-icon>
                            </template>
                            <ul>
                              <li>
                                Scheduled start time is only used in calculating
                                planned vs actual performance.
                              </li>
                              <li>
                                Updating scheduled start time will not affect
                                the execution order of the plan.
                              </li>
                              <li>
                                Plans are picked automatically in FIFO fashion.
                              </li>
                              <li>
                                To update the execution order, please visit
                                <strong>re-order plans section</strong>.
                              </li>
                            </ul>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <v-checkbox
                      hide-details
                      :label="$t('assemblyPlanningTaco.markAsStar')"
                      :disabled="saving"
                      v-model="plan.starred"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <v-checkbox
                      hide-details
                      :label="$t('assemblyPlanningTaco.markAsTrial')"
                      :disabled="saving || inProgress"
                      v-model="plan.trial"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <div>
                  {{ $t('assemblyPlanningTaco.estimatedEnd') }}
                  <strong>{{ estimatedEndDisplay }}</strong>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="primary"
                  class="text-none"
                  :disabled="saving"
                  @click="exit"
                >
                  {{ $t('assemblyPlanningTaco.exit') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="text-none"
                  :loading="saving || !selectedPart || !plan.plannedquantity  || !plan.remark"
                  type="submit"
                >
                  {{ $t('assemblyPlanningTaco.saveExit') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </validation-observer>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
// import { sortArray } from '@shopworx/services/util/sort.service';
import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'EditPlan',
  data() {
    return {
      dialog: false,
      headers: [
        { text: this.$t('assemblyPlanningTaco.part'), value: 'partname' },
        { text: this.$t('assemblyPlanningTaco.activeCavity'), value: 'activecavity' },
        { text: this.$t('assemblyPlanningTaco.quantity'), value: 'plannedquantity' },
      ],
      editParams: false,
      saving: false,
      fetchingMatrix: false,
      variantList: [],
      // customerList: [],
      lineList: [],
      partList: [],
      partMatrixList: [],
      selectedModel: null,
      selectedVariant: null,
      selectedCustomer: null,
      selectedLine: null,
      selectedPart: null,
      plan: {},
    };
  },
  computed: {
    ...mapState('assemblyPlanningTaco', [
      'parts',
      'partMatrix',
      'selectedPlan',
      'customerList',
    ]),
    ...mapGetters('assemblyPlanningTaco', ['selectedAsset', 'partMatrixTags']),
    ...mapGetters('helper', ['locale']),
    inProgress() {
      return this.selectedPlan[0].status === 'inProgress';
    },
    notStarted() {
      return this.selectedPlan[0].status === 'notStarted';
    },
    complete() {
      return this.selectedPlan[0].status === 'complete';
    },
    abort() {
      return this.selectedPlan[0].status === 'abort';
    },
    modelList() {
      return this.parts;
    },
    customers() {
      return this.customerList;
    },
    estimatedEndDisplay() {
      let res = '';
      if (this.plan.scheduledend) {
        res = formatDate(
          new Date(this.plan.scheduledend),
          'PPpp',
          { locale: this.locale },
        );
      }
      return res;
    },
    selectedMatrix() {
      if (this.selectedPart && this.selectedLine) {
        const partMatrix = this.partMatrixList.filter((matrix) => (
          matrix.partname === this.selectedPart.partname
          && matrix.linename === this.selectedLine
        ));
        return partMatrix.sort((a, b) => a.sequence - b.sequence);
      }
      return null;
    },
    shots() {
      if (this.plan.plannedquantity && this.plan.activecavity) {
        return +this.plan.plannedquantity / +this.plan.activecavity;
      }
      return 0;
    },
  },
  async created() {
    this.clear();
    await this.fetchCustomerList();
    this.setPlan();
    if (this.complete || this.abort) {
      this.dialog = true;
    }
  },
  methods: {
    ...mapMutations('helper', ['setAlert']),
    ...mapActions('assemblyPlanningTaco', [
      'fetchPartMatrix',
      // 'isFamilyMold',
      // 'getFamilyParts',
      'getScheduledEnd',
      'fetchLastPlan',
      'createPlans',
      'deletePlanById',
      'updatePlanById',
      'fetchModel',
      'fetchCustomerList',
    ]),
    initPlan() {
      this.plan = {
        assetid: 0,
        partname: '',
        linename: '',
        activecavity: 1,
        machinedetails: [],
        plannedquantity: 0,
        scheduledstart: formatDate(new Date(Math.ceil(new Date() / 9e5) * 9e5), 'yyyy-MM-dd\'T\'HH:mm'),
        scheduledend: '',
        starred: false,
        trial: false,
        sortindex: 0,
        status: 'notStarted',
        remark: '',
      };
    },
    async setPlan() {
      const [plan] = this.selectedPlan;
      this.selectedModel = this.modelList
        .find((p) => p.model === plan.model).model;
      this.fetchingMatrix = true;
      this.variantList = await this.fetchModel({ model: this.selectedModel });
      this.selectedVariant = this.variantList
        .find((p) => p.variant === plan.variant)
        .variant;
      this.filterCustomer();
      this.selectedCustomer = this.customers
        .find((p) => p.customername === plan.customer)
        .customername;
      this.filterLine();
      this.selectedLine = this.variantList
        .find((m) => m.linename === plan.linename)
        .linename;
      this.filterPart();
      this.selectedPart = this.variantList
        .find((p) => p.partname === plan.partname);
      this.fetchingMatrix = false;
      await this.setEditParams(plan);
    },
    async setEditParams(plan) {
      this.plan = {
        // eslint-disable-next-line
        assetid: plan.assetid,
        model: plan.model,
        variant: plan.variant,
        customer: plan.customer,
        partname: plan.partname,
        linename: plan.linename,
        machinedetails: plan.machinedetails,
        activecavity: plan.activecavity,
        plannedquantity: plan.plannedquantity,
        scheduledstart: formatDate(new Date(plan.scheduledstart), 'yyyy-MM-dd\'T\'HH:mm'),
        scheduledend: formatDate(new Date(plan.scheduledend), 'yyyy-MM-dd\'T\'HH:mm'),
        starred: plan.starred,
        trial: plan.trial,
        sortindex: plan.sortindex,
        status: plan.status,
        remark: plan.remark,
      };
    },
    clear() {
      this.initPlan();
      this.editParams = false;
      this.variantList = [];
      // this.customerList = [];
      this.lineList = [];
      this.partList = [];
      this.selectedModel = null;
      this.selectedVariant = null;
      this.selectedCustomer = null;
      this.selectedLine = null;
      this.selectedPart = null;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    async fetchPartByModel() {
      this.initPlan();
      this.variantList = [];
      // this.customerList = [];
      this.lineList = [];
      this.partList = [];
      this.selectedVariant = null;
      this.selectedCustomer = null;
      this.selectedLine = null;
      this.selectedPart = null;
      if (this.selectedModel) {
        this.fetchingMatrix = true;
        this.variantList = await this.fetchModel({ model: this.selectedModel });
        this.fetchingMatrix = false;
      }
    },
    filterCustomer() {
      this.initPlan();
      // this.customerList = [];
      this.lineList = [];
      this.partList = [];
      this.selectedCustomer = null;
      this.selectedLine = null;
      this.selectedPart = null;
      // if (this.selectedVariant) {
      //   this.customerList = this.variantList
      //     .filter((variant) => variant.variant === this.selectedVariant);
      // }
    },
    filterLine() {
      this.initPlan();
      this.lineList = [];
      this.partList = [];
      this.selectedLine = null;
      this.selectedPart = null;
      if (this.selectedCustomer) {
        // this.lineList = this.variantList;
        this.lineList = this.variantList
          .filter((variant) => variant.variant === this.selectedVariant);
      }
    },
    filterPart() {
      this.initPlan();
      this.partList = [];
      this.selectedPart = null;
      if (this.selectedLine) {
        this.partList = this.lineList
          .filter((line) => line.linename === this.selectedLine);
        this.partMatrixList = this.partList;
        [this.selectedPart] = this.partList;
        this.setPlanParameters();
      }
    },
    async setPlanParameters() {
      this.initPlan();
      if (this.selectedPart && this.selectedLine) {
        this.plan.assetid = this.selectedPart.assetid;
        this.plan.partname = this.selectedPart.partname;
        this.plan.model = this.selectedModel;
        this.plan.variant = this.selectedVariant;
        this.plan.customer = this.selectedCustomer;
        this.plan.linename = this.selectedLine;
        this.plan.activecavity = 1;
        this.plan.machinedetails = [];
        this.selectedMatrix.forEach((part) => {
          const {
            machinename,
            stdcycletime,
            linename,
            delaytime,
          } = part;
          this.plan.machinedetails.push({
            machinename,
            stdcycletime,
            linename,
            delaytime,
          });
        });
      }
    },
    async fetchEstimatedEnd() {
      if (this.plan.plannedquantity && this.plan.scheduledstart) {
        const machineCount = this.plan.machinedetails.length;
        const totalStdCycletime = this.plan.machinedetails.reduce((acc, cur) => {
          acc += (+cur.stdcycletime * 1000);
          return acc;
        }, 0);
        let ctAvg = 0;
        if (machineCount !== 0) {
          ctAvg = Math.floor(totalStdCycletime / machineCount);
        }
        const runTime = ((+this.plan.plannedquantity - 1)
          * ctAvg) + totalStdCycletime;
        this.plan.linestdcycletime = (runTime / 1000);
        this.plan.scheduledend = await this.getScheduledEnd({
          start: new Date(this.plan.scheduledstart).getTime(),
          duration: runTime,
        });
      } else {
        this.plan.scheduledend = '';
      }
    },
    // onCavityChange() {
    //   if (this.familyParts && this.familyParts.length) {
    //     this.updateFamilyParams();
    //   }
    // },
    onQuantityChange() {
      this.fetchEstimatedEnd();
      // if (this.familyParts && this.familyParts.length) {
      //   this.updateFamilyParams();
      // }
    },
    // updateFamilyParams() {
    //   this.familyParts = this.familyParts.map((p) => ({
    //     ...p,
    //     plannedquantity: +p.activecavity * this.shots,
    //   }));
    // },
    // updateFamilyCavity({ id, payload }) {
    //   // eslint-disable-next-line
    //   const index = this.familyParts.findIndex((p) => p._id === id);
    //   this.$set(this.familyParts, index, {
    //     ...this.familyParts[index],
    //     plannedquantity: +payload * this.shots,
    //   });
    // },
    exit() {
      this.clear();
      this.selectedPart = null;
      this.dialog = false;
      this.$router.go(-1);
    },
    async delete() {
      const ids = [
        // eslint-disable-next-line
        this.selectedPlan[0]._id,
        // eslint-disable-next-line
        // ...this.familyToEdit.map((p) => p._id),
      ];
      const deleted = await Promise.all(ids.map((id) => this.deletePlanById(id)));
      if (deleted.every((d) => d)) {
        return true;
      }
      return false;
    },
    async save() {
      // const matrixTags = await this.partMatrixTags(this.plan.assetid);
      // const partMatrix = matrixTags.reduce((acc, cur) => {
      //   acc[cur.tagName] = this.selectedMatrix[cur.tagName];
      //   return acc;
      // }, {});
      const payload = [{
        // ...partMatrix,
        ...this.plan,
        scheduledstart: new Date(this.plan.scheduledstart).getTime(),
        scheduledend: new Date(this.plan.scheduledend).getTime(),
        // familyName: 'family',
      }];
      // eslint-disable-next-line
      // const ids = [...new Set(this.selectedFamilyParts.map((p) => p._id))];
      // eslint-disable-next-line
      // this.selectedFamilyParts = this.familyParts.filter((p) => ids.includes(p._id));
      // if (this.selectedFamilyParts && this.selectedFamilyParts.length) {
      //   const familyPayload = this.selectedFamilyParts.map((p) => ({
      //     ...payload[0],
      //     cavity: p.cavity,
      //     activecavity: p.activecavity,
      //     plannedquantity: p.plannedquantity,
      //     partname: p.partname,
      //   }));
      //   payload = [...payload, ...familyPayload];
      // }
      const created = await this.createPlans(payload);
      if (created) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'PLAN_UPDATED',
        });
      } else {
        this.setAlert({
          show: true,
          type: 'error',
          message: 'PLAN_UPDATED',
        });
      }
      return created;
    },
    setEditPayload(payload) {
      const data = {
        plannedquantity: +payload.plannedquantity,
        machinedetails: payload.machinedetails,
        activecavity: +payload.activecavity,
        starred: this.plan.starred,
      };
      return data;
    },
    async update() {
      // let updatePayload = this.familyParts.map(({ id, ...p }) => ({
      //   id,
      //   payload: this.setEditPayload(p),
      // }));
      const updatePayload = [{
        // eslint-disable-next-line
        id: this.selectedPlan[0]._id,
        payload: this.setEditPayload(this.plan),
      }];
      const updated = await Promise.all(updatePayload.map((p) => this.updatePlanById(p)));
      if (updated.every((d) => d)) {
        this.setAlert({
          show: true,
          type: 'success',
          message: 'PLAN_UPDATED',
        });
        return true;
      }
      this.setAlert({
        show: true,
        type: 'error',
        message: 'PLAN_UPDATED',
      });
      return false;
    },
    async onSaveAndExit() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.saving = true;
        if (this.inProgress) {
          const updated = await this.update();
          if (updated) {
            this.exit();
          }
        } else {
          const deleted = await this.delete();
          if (deleted) {
            const created = await this.save();
            if (created) {
              this.exit();
            }
          }
        }
        this.saving = false;
      }
    },
  },
};
</script>
